














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HeroBanner extends Vue {
  @Prop() private msg!: string;
  @Prop() private title!: string;
  @Prop() private subtitle!: string;
  @Prop() private address!: string;
}
