







import { Component, Vue } from 'vue-property-decorator';
import HeroBanner from './components/HeroBanner.vue';
import DetailsBar from "@/components/DetailsBar.vue";

@Component({
  components: {
    DetailsBar,
    HeroBanner,
  },
})
export default class App extends Vue {}
